import React from 'react'
import { Form, Row, Container, Button, Col } from "react-bootstrap"

import LogoNama from '../logo/LogoNama'

function FormLogin() {
    return (
        <div className="Login">
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <LogoNama />
                            <Form.Group className="mb-3" controlId="formBasicInput">
                                <Form.Label>NIP/ NIK</Form.Label>
                                <Form.Control type="text" placeholder="NIP/ NIK Aktif" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
      </div>
    )
}

export default FormLogin
