import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom"
import ScrollUp from './components/helpers/ScrollUp'
import { Tabs, Tab } from 'react-bootstrap'

import { FcList,FcClock,FcPlanner,FcRight } from "react-icons/fc"

// Pages
import PageAbsensi from './components/absensi/PageAbsensi'
import PageJadwal from './components/jadwal/PageJadwal'
import PageKalender from './components/kalender/PageKalender'
import PageLogin from './components/form/PageLogin'

function App() {
  return (
        // source: https://stackoverflow.com/a/63002204 
        <Router>
          <ScrollUp>
            <Route path="/:tab">
            {({ match, history }) => {
                const { tab } = match ? match.params : {};
                return (
                <Tabs className="nav-justified" mountOnEnter={true} activeKey={tab} onSelect={nextTab => history.replace(nextTab)}>
                    <Tab eventKey="jadwal" title={<span><FcList/> Jadwal</span>}>
                        <PageJadwal />
                    </Tab>
                    <Tab eventKey="absensi" title={<span><FcClock/> Absensi</span>}>
                        <PageAbsensi />
                    </Tab>
                    <Tab eventKey="kalender" title={<span><FcPlanner/> Kalender</span>}>
                        <PageKalender />
                    </Tab>
                    <Tab eventKey="login" title={<span><FcRight/> Login</span>}>
                        <PageLogin />
                    </Tab>
                </Tabs>
                );
            }}
            </Route>
          </ScrollUp>
        </Router>
  );
}

export default App;
