import React, { Fragment } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async"
import LayoutOne from "../../layouts/LayoutOne"

// Komponen
import KalenderList from './KalenderList'

const PageKalender = () => {
    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Kalendar Zoom RSUP Persahabatan</title>
                    <link rel="canonical" href="https://zoom.rsuppersahabatan.co.id/kalender" />
                </Helmet>
                <LayoutOne>
                    <KalenderList />
                </LayoutOne>
            </HelmetProvider>
        </Fragment>
    )
}


export default PageKalender

