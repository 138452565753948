import React from 'react'
import { Link } from "react-router-dom"

function LogoNama() {
    return (
        <div style={{ height: "200px", marginTop: "20px" }}>
            <div className="text-center">
                <Link to={process.env.PUBLIC_URL + "/"}>
                    <img className="h-10" alt="Logo RSUP Persahabatan" src={process.env.PUBLIC_URL + '/logodannama.png'} />
                </Link>
            </div>
        </div>
    )
}

export default LogoNama
