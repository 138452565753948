import React, { Fragment } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async"
import LayoutOne from "../../layouts/LayoutOne"

// Komponen
import AbsensiList from './AbsensiList'
import Search from '../pencarian/Search'

const PageAbsensi = () => {
    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Absensi Zoom RSUP Persahabatan</title>
                    <link rel="canonical" href="https://zoom.rsuppersahabatan.co.id/absensi" />
                </Helmet>
                <LayoutOne>
                    <Search />
                    <AbsensiList />
                </LayoutOne>
            </HelmetProvider>
        </Fragment>
    )
}


export default PageAbsensi

