import React, { Fragment } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async"
import LayoutOne from "../../layouts/LayoutOne"

// Komponen
import Search from '../pencarian/Search'
import JadwalList from './JadwalList'

const PageJadwal = () => {
    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Jadwal Zoom RSUP Persahabatan</title>
                    <link rel="canonical" href="https://zoom.rsuppersahabatan.co.id/jadwal" />
                </Helmet>
                <LayoutOne>
                    <Search />
                    <JadwalList />
                </LayoutOne>
            </HelmetProvider>
        </Fragment>
    )
}


export default PageJadwal

