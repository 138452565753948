import PropTypes from "prop-types";
import React, { Fragment } from "react";

// Menus
// import TabMenus from "../components/menus/TabMenus";

const LayoutOne = ({ children }) => { //console.log(children)
  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any
};

export default LayoutOne;
