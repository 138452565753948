import React, { Fragment } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/id'
moment.locale("id-ID")
moment.updateLocale("id", {
	week: {
		dow: 1,
		doy: 4,
	},
});
const localizer = momentLocalizer(moment)

const messages = {
    allDay: 'Semua Hari',
    previous: '<',
    next: '>',
    today: 'Bulan Sekarang',
    month: 'Bulan',
    week: 'Pekan',
    day: 'Hari',
    agenda: 'Agenda',
    date: 'Tanggal',
    time: 'Waktu',
    event: 'Kegiatan',
    showMore: (total) => `+ (${total}) Kegiatan`,
}

export const KalenderList = (props) => {

    const state = {
        events: [
          {
            start: moment().toDate(),
            end: moment()
              .add(1, "days")
              .toDate(),
            title: "Some title"
          }
        ]
      };

    return (
        <Fragment>
        <>
            <div style={{ height: "1000px", marginTop: "20px" }}>
                <Calendar
                    messages={messages}
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={state.events}
                    style={{ height: "100vh" }}
                    formats={{
                      dayFormat: 'dddd, DD/MM/YYYY',
                      timeGutterFormat: 'h a',
                      dayHeaderFormat: 'DD MMMM YYYY',
                    }}
                    />
            </div>
        </>
        </Fragment>
    )
}

export default KalenderList
