import React from 'react'
import { FcSearch } from "react-icons/fc"


function Search() {
    return (
    <div className="row m-3">
        <div className="input-group col-6">
            <div className="input-group-prepend">
                <span className="input-group-text"> <FcSearch /> Pencarian </span>
            </div>
            <input
                type="text"
                className="form-control"
                placeholder="Cari"
                aria-label="Cari"
                // onChange=''
            />
        </div>
    </div>
    )
}

export default Search
