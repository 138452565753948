import { configureStore } from '@reduxjs/toolkit'

// slices
import jadwalSlice from './features/jadwal/jadwalSlice';

// Access
export default configureStore({
    reducer: {
        jadwal: jadwalSlice
    },
});