import React, { Fragment } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async"
import LayoutOne from "../../layouts/LayoutOne"

// Komponen
import FormLogin from './FormLogin'

const PageLogin = () => {
    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Form Login Zoom - RSUP Persahabatan</title>
                    <link rel="canonical" href="http://zoom.rsuppersahabatan.co.id/login" />
                </Helmet>
                <LayoutOne>
                    <FormLogin />
                </LayoutOne>
            </HelmetProvider>
        </Fragment>
    )
}


export default PageLogin

