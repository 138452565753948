import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// API REST
import backendAPI from '../../../api/website'

// source: https://github.com/sanderdebr/redux-crud-tutorial/blob/master/src/features/users/usersSlice.js dan https://stackoverflow.com/a/68452785
export const fetchJadwalList = createAsyncThunk("jadwal", async() => {
    const res = await backendAPI.get('/JadwalList');
    return res.data.jadwal; // source: https://stackoverflow.com/q/66753905
})

const jadwalSlice = createSlice({
    name: "jadwal",
    initialState: {
        loading: false,
        data: {}
    },
    reducers: {},
    extraReducers: {
        [fetchJadwalList.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchJadwalList.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [fetchJadwalList.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})

// export const { } = jadwalSlice.actions;
export default jadwalSlice.reducer;