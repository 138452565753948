import React, { useEffect } from 'react'
import { Table, Modal } from 'react-bootstrap'
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { useDispatch } from 'react-redux'

// Komponen Absensi Form
import AbsensiForm from '../absensi/AbsensiForm'

// Redux
import { fetchJadwalList } from '../../store/features/jadwal/jadwalSlice'

export const JadwalList = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchJadwalList());
    }, [dispatch])

    return (
        <>
        <Modal
            // show={this.state.showCustomerForm}
            // onHide={this.handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AbsensiForm
                    // customer={this.state.pendingUpdatingCustomer}
                    // onSubmit={this.handleSubmit}
                />
            </Modal.Body>
        </Modal>
        <div style={{ height: "1000px", marginTop: "20px" }}>
        <Table striped bordered hover className="responsiveTable">
            <Thead>
            <Tr>
                <Th>Nomor</Th>
                <Th>Nama Kegiatan/ Jadwal</Th>
                <Th>Tanggal</Th>
                <Th>Jam</Th>
                <Th>Unit Terkait</Th>
                <Th>Status</Th>
                <Th>Absensi</Th>
            </Tr>
            </Thead>
            <Tbody>
            <Tr>
                <Td>1</Td>
                <Td>Zoom Lanjutan	</Td>
                <Td>27 September 2021 s.d 27 September 2021	</Td>
                <Td>11:38 s.d 18:38	</Td>
                <Td>Hukormas</Td>
                <Td>Selesai</Td>
                <Td>						
                    <button
                        type="button"
                        className="btn btn-primary"
                        // onClick=''
                    >
                        Check
                    </button>
                </Td>
            </Tr>
            <Tr>
                <Td>2</Td>
                <Td>Zoom Lanjutan	</Td>
                <Td>27 September 2021 s.d 27 September 2021	</Td>
                <Td>11:38 s.d 18:38	</Td>
                <Td>Hukormas</Td>
                <Td>Selesai</Td>
                <Td>						
                    <button
                        type="button"
                        className="btn btn-primary"
                        // onClick=''
                    >
                        Check
                    </button>
                </Td>
            </Tr>
            <Tr>
                <Td>3</Td>
                <Td>Zoom Lanjutan	</Td>
                <Td>27 September 2021 s.d 27 September 2021	</Td>
                <Td>11:38 s.d 18:38	</Td>
                <Td>Hukormas</Td>
                <Td>Selesai</Td>
                <Td>						
                    <button
                        type="button"
                        className="btn btn-primary"
                        // onClick=''
                    >
                        Check
                    </button>
                </Td>
            </Tr>
        </Tbody>
        </Table>
        </div>
        </>
    )
}

export default JadwalList
