import React, { Fragment } from 'react'
import { Table } from 'react-bootstrap'
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

export const AbsensiList = (props) => {
    return (
        <Fragment>
        <div style={{ height: "1000px", marginTop: "20px" }}>
        <Table striped bordered hover className="responsiveTable">
            <Thead>
            <Tr>
                <Th>Nomor</Th>
                <Th>Nama Kegiatan/ Jadwal</Th>
                <Th>Tanggal</Th>
                <Th>Jam</Th>
                <Th>Unit Terkait</Th>
                <Th>Status</Th>
                <Th>Absensi</Th>
            </Tr>
            </Thead>
            <Tbody>
            <Tr>
                <Td>1</Td>
                <Td>Absensi Lanjutan	</Td>
                <Td>27 September 2021 s.d 27 September 2021	</Td>
                <Td>11:38 s.d 18:38	</Td>
                <Td>Hukormas</Td>
                <Td>Selesai</Td>
                <Td>						
                    <button
                        type="button"
                        className="btn btn-primary"
                        // onClick=''
                    >
                        Check
                    </button>
                </Td>
            </Tr>
            <Tr>
                <Td>2</Td>
                <Td>Absensi Lanjutan	</Td>
                <Td>27 September 2021 s.d 27 September 2021	</Td>
                <Td>11:38 s.d 18:38	</Td>
                <Td>Hukormas</Td>
                <Td>Selesai</Td>
                <Td>						
                    <button
                        type="button"
                        className="btn btn-primary"
                        // onClick=''
                    >
                        Check
                    </button>
                </Td>
            </Tr>
            <Tr>
                <Td>3</Td>
                <Td>Absensi Lanjutan	</Td>
                <Td>27 September 2021 s.d 27 September 2021	</Td>
                <Td>11:38 s.d 18:38	</Td>
                <Td>Hukormas</Td>
                <Td>Selesai</Td>
                <Td>						
                    <button
                        type="button"
                        className="btn btn-primary"
                        // onClick=''
                    >
                        Check
                    </button>
                </Td>
            </Tr>
        </Tbody>
        </Table>
        </div>
        </Fragment>
    )
}

export default AbsensiList

